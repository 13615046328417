@use "../abstracts/variables";
@use "../theme";
@use "../abstracts/colors";
@use "../abstracts/mixins";

.ltb-button {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  border-radius: 3px;
  .ltb-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
    backdrop-filter: blur(2px);
    &__spinner__path {
      stroke: #fff;
    }
  }
}
.avish-button {
  border-radius: variables.$border-radius;
  font-size: 0.8em;
  border: 1px solid #ccd;
  padding: 0.5em 0.7em;
  font-weight: 500;
  transition: all 0.15s linear;
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  line-height: 1;

  &:hover {
    box-shadow: inset 0 0 50px rgb(21 74 119 / 24%);
  }

  &:focus {
    outline: 1px dashed #8aadc8;
    outline-offset: -3px;
  }
  &.default {
    background-color: #e6e6e6;
  }
  &.primary {
    @include theme.theme-aware("background-color", "primary-color");
    color: #fff;
    @include theme.theme-aware(
      "border-color",
      "primary-color",
      $saturation: 41%,
      $lightness: 32%
    );
    &:focus {
      outline-color: #fff;
    }
  }
  &.danger {
    background: colors.$danger-color;
    color: #fff;
    border-color: colors.$danger-color;
    &:focus {
      outline-color: #fff;
    }
  }

  &.success {
    background: colors.$success-color;
    color: #fff;
    border-color: colors.$success-color;
    &:focus {
      outline-color: #fff;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .ltb-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(5 16 21 / 82%);
    @include mixins.flex();
    z-index: 1;
    color: #fff;
    border-radius: variables.$border-radius;
    overflow: hidden;
    svg {
      @include mixins.size(0.8rem);
    }
  }

  &.loading {
    border-color: transparent;
  }

  svg {
    display: inline-flex;
    @include mixins.size(1em);
  }
  &.icon {
    padding: 0.27em 0.4em;
  }

  &__wrapper {
    display: inline;
  }

  &.muted {
    background-color: #e3f1f8;
    border-color: #c3d0d6;
    &.danger {
      background-color: #e4cbc9;
      border-color: #dbb2af;
      color: #424242;
    }

    &.primary {
      background-color: #def5ff;
      color: #486f7f;
    }
    &.success {
      background-color: #deeedf;
      color: #247d27;
    }
  }

  &__group {
    @include mixins.flex(inline-flex, center);
    .avish-button__wrapper {
      .avish-button {
        border-radius: 0;
      }
      &:first-child .avish-button {
        border-top-left-radius: variables.$border-radius;
        border-bottom-left-radius: variables.$border-radius;
      }
      &:last-child .avish-button {
        border-top-right-radius: variables.$border-radius;
        border-bottom-right-radius: variables.$border-radius;
      }
    }
  }

  &__prefix {
    display: inline-flex;
  }
  &.no-borders {
    border-style: none !important;
  }
  &.rounded {
    border-radius: 50% !important;
    padding: 0.4em;
  }
}
