@use "../abstracts/variables";
@use "../abstracts/mixins";
@use "../theme";

.input-style {
  border-radius: variables.$border-radius;
  height: 2rem;
  border: 1px solid #c1cad1;
  font-size: 0.8rem;
  padding: 0 0.5rem;
  transition: all 0.2s ease-in-out;
  outline: 3px solid transparent;
  outline-offset: 10px;
  position: relative;

  &:focus,
  &:focus-visible {
    outline: 3px solid #03a9f426;
    @include theme.theme-aware("border-color",
      "primary-color",
      $saturation: 79%,
      $lightness: 63%);
    @include theme.theme-aware("background-color",
      "primary-color",
      $saturation: 100%,
      $lightness: 98%);
    outline-offset: 0;
  }

  &:hover {
    @include theme.theme-aware("background-color",
      "primary-color",
      $saturation: 100%,
      $lightness: 98%);
  }

  &::placeholder {
    font-size: 0.8rem;
    color: #7c888e;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #eaeaea;

    &:hover {
      background-color: #eaeaea;
    }
  }
}

.form-element {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &.has-clear-icon {
    overflow: hidden;
  }

  &:focus-within {
    label {
      @include theme.theme-aware("color",
        "primary-color",
        $saturation: 79%,
        $lightness: 63%);
    }
  }

  label {
    margin-bottom: 0.3rem;
    font-size: 0.7rem;
    color: #0f0f0f;
    font-weight: 500;
  }

  .input {
    @extend .input-style;
  }

  .select {
    appearance: none;
    background-image: none;

    select {
      font-size: 0.7rem;
      cursor: pointer;
      width: 100%;
    }

    position: relative;
    display: flex;
    overflow: hidden;

    &::after {
      content: "\25BC";
      position: absolute;
      top: 1px;
      right: 1px;
      padding: 0 0.35rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #607d8b;
      font-size: 0.65rem;
      transition: 0.25s all ease;
      pointer-events: none;
      height: calc(100% - 2px);
      background-color: whitesmoke;
      border-top-right-radius: variables.$border-radius;
      border-bottom-right-radius: variables.$border-radius;
    }
  }

  .textarea {
    width: 100%;
    resize: none;
    height: auto;
    padding: 0.4rem 0.5rem !important;
  }

  .input-error-message {
    font-size: 0.75rem;
    color: #e91e63;
    margin-top: 0.2rem;
    display: flex;
    gap: 0.2rem;
    justify-content: flex-end;

    svg {
      flex-shrink: 0;
      @include mixins.size(0.8rem);
    }

    span {
      line-height: 1;
    }
  }

  &.error {
    label {
      color: #e91e63;
    }

    input {
      border-color: #e91e638f;
      background-color: #e91e630d;
    }
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    border-radius: variables.$border-radius;

    input {
      width: 100%;
    }

    .ltb-loader,
    .clear-input {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &:hover .clear-input {
      animation: slide-left 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      @keyframes slide-left {
        0% {
          transform: translateX(100%);
        }

        100% {
          transform: translateX(0);
        }
      }

      &:hover {
        background-color: #ff00000d;
        color: #e91e63;
      }
    }

    position: relative;

    .input-suffix {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
    }

    input[type="checkbox"] {
      @include mixins.size(0.8rem);
    }
  }

  &.loading {
    input {
      padding-right: 2.5rem;
    }
  }

  .clear-input {
    cursor: pointer;
    transform: translateX(100%);

    svg {
      @include mixins.size(1rem);
    }
  }

  &+.form-element:last-child {
    margin-bottom: 0;
  }

  &.checkbox {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 0.4rem;
    justify-content: flex-end;

    .input-wrapper {
      display: inline-flex;
      align-items: center;
      padding: 0;
      height: 100%;
      border-radius: 0;
      width: min-content;
    }

    label {
      display: inline-flex;
      white-space: nowrap;
      font-size: 0.8rem;
      color: #000;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-groups {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;

  .form-element {
    margin-bottom: 0 !important;
    flex-grow: 1;
  }
}

// .ck-editor__top {
//   display: none !important;
// }

// .ck-editor__editable {
//   min-height: 50px !important;
//   border-radius: .25rem !important;
//   border: 1px solid #ced4da !important;
// }

.ck-editor {
  &__top {
    display: none !important;
  }

  &__editable {
    min-height: 50px !important;
    border-radius: .25rem !important;
    border: 1px solid #ced4da !important;
  }
}