@use "../abstracts/mixins";
@use "../abstracts/colors";
@use "../abstracts/variables";
@use "../theme";

// Here you can add other styles
.avish-page-wrapper {
  height: 100%;
}

.nocontent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  svg {
    @include mixins.size(4.5rem);
  }
  color: #929292;
}

.cursor-pointer {
  cursor: pointer;
}

.template-app-datatable thead {
  display: none;
}

.primary-color {
  @include theme.theme-aware("color", "primary-color");
}
.primary-bg {
  @include theme.theme-aware("background-color", "primary-color");
}

.success-color {
  color: colors.$success-color;
}
.success-bg {
  background-color: colors.$success-color;
}

.warning-color {
  color: colors.$warning-color;
}
.warning-bg {
  background-color: colors.$warning-color;
}

.danger-color {
  color: colors.$danger-color;
}
.danger-bg {
  background-color: colors.$danger-color;
}

.gray-color {
  color: colors.$primary-gray;
}
.gray-bg {
  background-color: colors.$primary-gray;
}

.copy-text-parent {
  position: relative;
  .copy-text {
    position: absolute;
    top: 105%;
    left: -50%;
    font-size: 0.7rem;
    color: #2e1a52;
    background-color: lightgreen;
    border-radius: 3px;
    padding: 0.3em 0.5em;
    line-height: 1;
    box-shadow: variables.$shadow-elevation-medium;
  }
}

.avish-alert {
  padding: 0.5rem;
  background: #f2e0e0;
  border-radius: 0.3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: #d55f56;
}
.hidden {
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.9;
  filter: grayscale(1);
  background: #f8f8f8;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  -o-user-select: none;
  user-select: none;
}

.info {
  display: inline-flex;
  align-items: center;
  padding: 0.2rem;
  color: #2196f3;
  svg {
    @include mixins.size(0.8rem);
  }
}

.rotate-90-deg {
  transform: rotate(90deg);
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
