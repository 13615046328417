@use "../abstracts/mixins";
@use "../abstracts/variables";
@use "../base/scroll";
@use "../abstracts/colors";
@use "../theme";

.element-information {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  outline: 1px solid transparent;
  outline-offset: -1px;
  &__activator {
    @include mixins.size(0.6rem);
    background: linear-gradient(
      225deg,
      colors.$primary-color 0%,
      hsla(186, 100%, 69%, 1) 100%
    );
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    border-radius: 0.2rem;
    cursor: pointer;
  }
  &:hover {
    height: 100%;
    width: 100%;
    @include theme.theme-aware("outline-color", "primary-color");
    outline-offset: -3px;
    .element-information__activator {
      @include mixins.size(100%);
      clip-path: none;
      background: transparent;
    }
  }

  &__body {
    position: fixed;
    display: none;
    max-width: 300px;
    min-width: 100px;
    max-height: 10rem;
    min-height: auto;
    z-index: map-get(variables.$z-indexes, "info-element");
    // padding-top: 13px;
    inset: 0 auto auto 0;
    &__wrapper {
      background-color: white;
      border: 1px solid #ccd;
      border-radius: variables.$border-radius;
      box-shadow: variables.$shadow-elevation-medium;
      flex-direction: column;
      @include mixins.size(100%);
    }

    &__title {
      flex-shrink: 1;
      padding: 0 1rem;
      height: 2rem;
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 500;
    }
    &__message {
      flex-grow: 1;
      overflow-y: auto;

      padding: 1rem 1rem;
      padding-top: 0;
      @extend .scroll-vertical;
    }
  }

  &__activator__hidden {
    &.element-information {
      @include mixins.size(100%);
    }
    .element-information {
      &__activator {
        @include mixins.size(100%);
        background: none;
        clip-path: unset;
      }
    }
  }
}
