.scroll-overlay {
  overflow-y: overlay !important;
}
.scroll-hidden {
  overflow-y: hidden !important;
}

.scroll-vertical,
.scroll-horizontal,
textarea {
  overflow-y: auto;
  &.scroll-overlay {
    overflow-y: overlay !important;
  }
  transition: all 0.2s ease;
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #8b8686;
  }
  &:hover {
    &::-webkit-scrollbar {
      width: 6px;
      &:hover {
        width: 10px !important;
        background-color: rgba(159, 159, 159, 0.16);
      }
    }
  }
}
.scroll-horizontal {
  overflow-x: auto;
  &.scroll-overlay {
    overflow-x: overlay !important;
  }
  &::-webkit-scrollbar {
    height: 0px;
  }
  &:hover {
    &::-webkit-scrollbar {
      height: 6px;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .scroll-vertical,
  .scroll-horizontal,
  textarea {
    &.scroll-overlay {
      overflow-y: auto !important;
    }
  }
}
