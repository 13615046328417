// Variable overrides

:root {
  --shadow-color: 0deg 0% 68%;
}
$primary-color: #03a9f4;
$theme-colors: (
  color: #9ba3aa,
);
$sidebar-dark-nav-link-hover-color: white !default;
$sidebar-dark-nav-link-hover-bg: #8f999c !default;
$sidebar-dark-nav-link-hover-icon-color: #2f8dda !default;

$border-radius: 0.3rem;

$shadow-elevation-low: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.27),
  0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.27),
  0.9px 1.8px 2.3px -2.5px hsl(var(--shadow-color) / 0.27);
$shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.23),
  0.6px 1.2px 1.5px -0.6px hsl(var(--shadow-color) / 0.23),
  1.2px 2.3px 2.9px -1.2px hsl(var(--shadow-color) / 0.23),
  2.3px 4.7px 5.9px -1.9px hsl(var(--shadow-color) / 0.23),
  4.5px 9px 11.3px -2.5px hsl(var(--shadow-color) / 0.23);
$shadow-elevation-high: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.21),
  1px 2px 2.5px -0.3px hsl(var(--shadow-color) / 0.21),
  1.7px 3.5px 4.4px -0.6px hsl(var(--shadow-color) / 0.21),
  2.6px 5.2px 6.5px -0.8px hsl(var(--shadow-color) / 0.21),
  3.8px 7.5px 9.5px -1.1px hsl(var(--shadow-color) / 0.21),
  5.4px 10.8px 13.6px -1.4px hsl(var(--shadow-color) / 0.21),
  7.7px 15.3px 19.3px -1.7px hsl(var(--shadow-color) / 0.21),
  10.7px 21.4px 26.9px -1.9px hsl(var(--shadow-color) / 0.21),
  14.7px 29.3px 36.9px -2.2px hsl(var(--shadow-color) / 0.21),
  19.8px 39.5px 49.7px -2.5px hsl(var(--shadow-color) / 0.21);

$shadow-top-elevation-low: -0.1px -0.1px 0.2px hsl(var(--shadow-color) / 0.1),
  -0.1px -0.1px 0.2px -1.2px hsl(var(--shadow-color) / 0.1),
  -0.2px -0.2px 0.3px -2.5px hsl(var(--shadow-color) / 0.1);
$shadow-top-elevation-medium: -0.1px -0.1px 0.2px hsl(var(--shadow-color) / 0.09),
  -0.2px -0.1px 0.3px -0.6px hsl(var(--shadow-color) / 0.09),
  -0.3px -0.3px 0.5px -1.2px hsl(var(--shadow-color) / 0.09),
  -0.6px -0.5px 0.9px -1.9px hsl(var(--shadow-color) / 0.09),
  -1.2px -1px 1.8px -2.5px hsl(var(--shadow-color) / 0.09);
$shadow-top-elevation-high: -0.1px -0.1px 0.2px hsl(var(--shadow-color) / 0.08),
  -0.3px -0.2px 0.4px -0.3px hsl(var(--shadow-color) / 0.08),
  -0.5px -0.4px 0.7px -0.6px hsl(var(--shadow-color) / 0.08),
  -0.8px -0.7px 1.2px -0.8px hsl(var(--shadow-color) / 0.08),
  -1.1px -1px 1.7px -1.1px hsl(var(--shadow-color) / 0.08),
  -1.6px -1.4px 2.4px -1.4px hsl(var(--shadow-color) / 0.08),
  -2.3px -2px 3.4px -1.7px hsl(var(--shadow-color) / 0.08),
  -3.3px -2.8px 4.9px -1.9px hsl(var(--shadow-color) / 0.08),
  -4.5px -3.8px 6.6px -2.2px hsl(var(--shadow-color) / 0.08),
  -6px -5.1px 8.9px -2.5px hsl(var(--shadow-color) / 0.08);

$z-indexes: (
  "modal": 10000,
  "tooltip": 10001,
  "toast": 10002,
  "dropdown": 10003,
  "select-input": 10005,
  "info-element": 10005,
);
