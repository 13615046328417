@use "../abstracts/variables";
@use "../abstracts/mixins";

*[data-tooltip] {
  overflow: visible;
  position: relative;
  &:hover {
    &::after,
    &::before {
      position: absolute;
      top: -0.25rem;
      left: 50%;
      transform: translate(-50%, -100%);
    }

    &::before {
      content: attr(data-tooltip);
      color: white;
      padding: 0.5em 0.8em;
      border-radius: variables.$border-radius;
      width: -webkit-max-content;
      max-width: 150px;
      background-color: rgba(54, 54, 71, 0.934);
      z-index: map-get(variables.$z-indexes, "tooltip");
      box-shadow: variables.$shadow-elevation-medium;
      font-size: 0.75rem;
      line-height: 1.2;
      overflow: hidden;
    }
    &::after {
      content: "";
      @include mixins.size(10px, -webkit-fill-available);
      background-color: rgb(76 76 92);
      z-index: map-get(variables.$z-indexes, "tooltip");
      clip-path: polygon(50% 50%, calc(50% - 5px) 0, calc(50% + 5px) 0);
      top: 0;
    }
  }

  &.tooltip-bottom {
    &::after,
    &::before {
      top: unset;
      bottom: -0.25rem;
      left: 50%;
      transform: translate(-50%, 100%);
    }
    &::after {
      transform: rotate(180deg) translate(50%, calc(100% - 10px));
    }
  }

  &.tooltip-bottom-right {
    &::after,
    &::before {
      top: unset;
      bottom: -0.25rem;
    }
    &::after {
      transform: rotate(180deg) translate(50%, calc(100% - 10px));
    }
    &::before {
      transform: translate(0, 100%);
      left: unset;
      right: 0;
    }
  }
  &.tooltip-bottom-left {
    &::after,
    &::before {
      top: unset;
      bottom: -0.25rem;
    }
    &::after {
      transform: rotate(180deg) translate(50%, calc(100% - 10px));
    }
    &::before {
      transform: translate(0, 100%);
      left: unset;
      left: 0;
    }
  }

  &.tooltip-top-right {
    &::after,
    &::before {
      left: unset;
      right: 0;
    }
    &::after {
      transform: translate(0%, calc(100% - 15px));
    }
    &::before {
      transform: translate(0, -100%);
    }
  }
  &.tooltip-top-left {
    &::after,
    &::before {
      left: 0;
    }
    &::after {
      transform: translate(0%, calc(100% - 15px));
    }
    &::before {
      transform: translate(0, -100%);
    }
  }
}
