@use "../abstracts/variables";
@use "../abstracts/mixins";
@use "../abstracts/colors";
@use "../theme";

$toast-types: (
  "primary": colors.$primary-color,
  "success": colors.$success-color,
  "danger": colors.$danger-color,
  "warning": colors.$warning-color,
  "loader": colors.$primary-color,
);

@mixin toast-color() {
  @each $key, $val in $toast-types {
    .ltb-toaster__toast {
      &__#{$key} {
        .ltb-toaster__toast__icon {
          @include mixins.flex();
          color: $val;
          svg {
            @include mixins.size(1.8rem);
          }
        }

        @if $key == "loader" {
          @include theme.theme-aware("background-color", "primary-color");
          color: #fff;
          .ltb-toaster__toast__icon {
            color: #fff;
            svg {
              @include mixins.size(1.6rem);
            }
          }
        } @else {
          // background-image: radial-gradient(
          //   ellipse 15% 100% at 0%,
          //   rgba($val, 0.5),
          //   rgb(235 235 235)
          // );

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            height: 1.5px;
            width: 100%;
            background-color: rgba(colors.$success-color, 1);
            animation: toast-progress 5000ms linear forwards;
            z-index: 1;

            @keyframes toast-progress {
              from {
                width: 100%;
                background-color: rgba(colors.$success-color, 1);
              }
              to {
                width: 0%;
                background-color: rgba($val, 1);
              }
            }
          }
        }
      }
    }
  }
}

@include toast-color();

.ltb-toaster {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: map-get(variables.$z-indexes, "toast");
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 0.5rem;

  &__toast {
    padding: 0.4rem;
    border-radius: variables.$border-radius;
    box-shadow: variables.$shadow-elevation-high;
    // backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;

    position: relative;

    transform: translateY(100%);
    opacity: 0;
    animation: add-toast 0.2s linear forwards;
    background-color: #fff;
    overflow: hidden;
    @keyframes add-toast {
      from {
        transform: translateY(-100%);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    transition: all 0.2s linear;

    &__icon {
      svg {
        @include mixins.size(2rem);
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      &__title {
        font-size: 0.85rem;
        font-weight: bold;
        line-height: 1;
      }
      &__description {
        font-size: 0.8rem;
        max-width: 300px;
        word-wrap: normal;
        line-height: 1.2;
      }
    }

    &__close {
      cursor: pointer;
      @include mixins.flex();
      padding: 0.4rem;
      border-radius: variables.$border-radius;
      svg {
        display: inline-flex;
        @include mixins.size(0.7rem);
      }

      &:hover {
        background-color: rgba(#000, 0.05);
      }
    }
  }
}
