@use "../abstracts/mixins";
@use "../theme";
@use "../abstracts/variables";

.avish-sidebar {
  .brand {
    gap: 0.5rem;
    justify-content: flex-start;
    padding: 0 1rem;
    .orgBrand {
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      height: 3rem;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .brand-name {
      font-size: 0.9rem;
      font-weight: 700;
      color: #000;
      line-height: 1.2;
      &__wrapper {
        @include mixins.flex(flex, $dir: column, $align: flex-start);
        small {
          color: #5b5b5b;
          line-height: 1.2;
        }
      }
    }
  }
  .c-sidebar-brand,
  .orgBrand img {
    background-color: transparent;
  }
  .c-sidebar-nav-item,
  .c-sidebar-nav-dropdown {
    border: none;
  }
  .c-sidebar-nav-dropdown {
    &.c-show {
      background-color: transparent;
      border-bottom: 0.5px dashed #ccd;
      border-top: 0.5px dashed #ccd;
    }
  }

  .c-sidebar-nav-dropdown-toggle,
  .c-sidebar-nav-link {
    padding-top: 0;
    padding-bottom: 0;
    height: 2.7rem;
    font-weight: 600;
    font-size: 0.8rem;
    font-family: inherit;
    position: relative;
    svg {
      flex: 0 0 56px;
      height: 1.09375rem;
      font-size: 1.09375rem;
      text-align: center;
      transition: 0.3s;
      color: #5b5b5b;
    }
    &::before {
      content: "";
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      @include mixins.size(calc(100% - 0.5rem), calc(100% - 1rem));
      opacity: 0;
      z-index: -1;
      border-radius: variables.$border-radius;
    }
    &:hover {
      background-color: transparent;
      color: inherit;
      transition: 0.3s;
      //   font-weight: 700;
      color: #000;
      &::before {
        opacity: 1;
        @include theme.theme-aware(
          "background-color",
          "primary-color",
          $saturation: 100%,
          $lightness: 96%
        );
      }

      &::after {
        filter: brightness(0);
      }

      svg {
        @include theme.theme-aware("color", "primary-color");
      }
    }

    &.c-active {
      background-color: transparent;
      color: #000;
      svg {
        @include theme.theme-aware("color", "primary-color");
        @include mixins.size(1.3rem);
      }
      &::before {
        opacity: 1;
        @include theme.theme-aware(
          "background-color",
          "primary-color",
          $saturation: 100%,
          $lightness: 90%
        );
      }
    }
  }

  .c-sidebar-minimizer {
    background-color: transparent;
    flex: 0 0 2.5rem;
    &::before {
      filter: hue-rotate(98deg);
      height: 2.5rem;
      background-size: 10px;
    }
    &:hover {
      @include theme.theme-aware("background-color", "primary-color");
    }
  }
}

.c-sidebar-minimized.avish-sidebar {
  .orgBrand {
    height: 2.5rem;
  }
  .brand {
    padding: 0;
    justify-content: center;
  }
  .brand-name {
    &__wrapper {
      display: none;
    }
  }
  .c-sidebar-nav-dropdown-toggle,
  .c-sidebar-nav-link {
    svg {
      margin-left: -1rem;
    }
  }
  .c-sidebar-nav-link {
    z-index: 1;
    padding-left: 1rem !important;
  }

  .c-sidebar-nav > .c-sidebar-nav-dropdown {
    &:hover {
      background-color: #fff;
    }

    > .c-sidebar-nav-dropdown-items {
      box-shadow: variables.$shadow-elevation-medium;
      border-top-right-radius: variables.$border-radius;
      border-bottom-right-radius: variables.$border-radius;
    }
  }
  .c-sidebar-nav-item:hover > .c-sidebar-nav-link {
    background-color: transparent;
  }
}
