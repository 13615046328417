@use "../abstracts/mixins";

.avish-datatable {
  &__wrapper {
    .table-responsive {
      overflow-y: visible !important;
      @extend .scroll-horizontal;
      @extend .scroll-overlay;
      &::-webkit-scrollbar-thumb {
        background-color: #ddd;
      }
    }
    .table {
      background-color: #fff;
      border-radius: 6px;
      th,
      td {
        padding: 0.3rem;
      }
      thead tr th {
        > div {
          font-size: 0.8rem;
          font-weight: 500;
          line-height: 1;
          display: inline-block !important;
        }
        svg {
          @include mixins.size(0.6rem);
          fill: #388e3c;
        }
      }
      tbody tr {
        &:hover {
          background-color: rgb(47 117 173 / 11%);
        }
        td {
          font-size: 0.8rem;
          color: #5d6c77;
          .dropdown {
            @include mixins.flex();
          }
        }
      }

      .datatable-nocontent {
        @include mixins.flex();
        flex-direction: column;
        padding: 1rem;
        opacity: 0.7;
        gap: 1rem;
        svg {
          @include mixins.size(6rem, auto);
        }
        &__message {
          font-size: 0.8rem;
        }
      }
    }

    .datatable-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include mixins.flex();
      background-color: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      z-index: 100;
      border-radius: 6px;
      overflow: hidden;
      svg {
        @include mixins.size(1.5rem);
      }
    }
    .c-datatable-filter {
      label {
        display: none;
      }
    }
    .form-control {
      height: 1.8rem;
      font-size: 0.8rem;
      padding: 0 0.5rem;
    }
    nav {
      li {
        @include mixins.flex();
        @include mixins.size(1.4rem);
        a,
        span {
          padding: 0;
          height: 100%;
          width: 100%;
          @include mixins.flex();
          line-height: 1;
          font-size: 0.8rem;
        }
        &.disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
      }
    }
  }
}

.description-container {
  font-size: 11px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  word-wrap: break-word;
  transition: max-height 0.3s ease;

  &.expanded {
    -webkit-line-clamp: unset;
    overflow: visible;
  }

  &.collapsed {
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  p, div {
    display: inline;
  }
}
