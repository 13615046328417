@use "../base/scroll";

@mixin flex($display: flex, $align: center, $justify: center, $dir: row) {
  display: $display;
  flex-direction: $dir;
  align-items: $align;
  justify-content: $justify;
}

@mixin size($height, $width: "") {
  height: $height;
  @if ($width == "") {
    width: $height;
  } @else {
    width: $width;
  }
}

@mixin scroll-vertical($isOverlay: 0) {
  @extend .scroll-vertical;
  transition: all 0.2s ease-in-out;
  @if $isOverlay == 1 {
    @extend .scroll-overlay;
  }
}
