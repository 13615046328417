/* body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #f1f3f6;
  font-size: 13px;
  color: #6a6c6f;
  margin: 0;
  padding: 0;
  height: 100%;
} */
/* width */
/* div ::-webkit-scrollbar {
  width: 1px;
} */
/* #style-2::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
} */
body h1 {
  font-size: 18px;
}
.logInUser {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
.loginFooter {
  text-align: center;
  margin-top: 4%;
  justify-content: center;
  align-items: center;
  width: auto;
}
form {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
}
/* .chartDiv {
  margin-left: 10%;
} */
.chartBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 38.5%;
}
.centerTwitterContent {
  overflow: scroll;
  height: 400px;
}
.lawtoolContent {
  display: flex;
  margin-top: 2%;
}
.spinnerDiv {
  justify-content: center;
  position: "fixed";
  align-items: center;
  margin-top: "50%";
  margin-left: "50%";
  transform: "translate(-50%, -50%)";
  z-index: 99999;
}

.firmDetail {
  display: flex;
}
.well {
  border: 3px outset #c8f6fa;
  text-align: center;
}
.checkLabel {
  font-size: 16px;
  padding: 8px;
}
.textBold {
  font-weight: 600;
}
.progress {
  background-color: red;
}
.matterFilter {
  font-size: 13px;
}

.left {
  margin-left: 1.5rem;
}

.marginLeft {
  margin-left: 0.5rem;
}

.bottom {
  margin-bottom: 1rem;
}

.textInfo {
  color: #3498db;
  font-size: medium;
}
.badgeColor {
  background-color: #337ab7;
  color: white;
}
.caseSharingHead {
  background-color: #6a6c6f;
}

/* navbar  */

.sidenav {
  width: 280px;
  background: #fff;
  position: absolute;
  height: 84vh;
  right: -100%;
  z-index: 999;
}

.sidenav.active {
  right: 0;
}
.tags .tag {
  display: inline-block !important;
  font-size: 10px;
  font-weight: 600;
  background-color: #eee;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  padding: 2px 5px;
  line-height: 1;
  min-height: 10px;
  color: #555;
}
.c-sidebar {
  color: black;
  background: #fff;
}
c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: black;
  background: grey;
}
.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: black;
  background: transparent;
  font-weight: bold;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
  color: black;
}
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: black;
}
.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: black;
  background: grey;
}
c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: black;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: black;
}
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: black;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav
  > .c-sidebar-nav-dropdown
  > .c-sidebar-nav-dropdown-items {
  background: #ffffff;
}
.c-sidebar-nav-item {
  border-top: red;
  border-width: 1px;
}
.c-sidebar-nav-dropdown {
  border-width: 1px;
}

.orgBrand img,
video {
  max-width: 98%;
  height: auto;
  background-color: #f7f9fa;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
}
.form-textarea-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.color-line {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: -moz-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: -ms-linear-gradient(
    left,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-image: linear-gradient(
    to right,
    #34495e,
    #34495e 25%,
    #9b59b6 25%,
    #9b59b6 35%,
    #3498db 35%,
    #3498db 45%,
    #62cb31 45%,
    #62cb31 55%,
    #ffb606 55%,
    #ffb606 65%,
    #e67e22 65%,
    #e67e22 75%,
    #e74c3c 85%,
    #e74c3c 85%,
    #c0392b 85%,
    #c0392b 100%
  );
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #f1f3f6;
  font-size: 13px;
  color: #6a6c6f;
  margin: 0;
  padding: 0;
  height: 100%;
  line-height: 1.42857143;
  display: block;
}

.login-container {
  max-width: 420px;
  margin: auto;
   padding-top: 6%;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.form-group {
  margin-bottom: 15px;
}
.body.blank {
  background-color: #f1f3f6;
}
.tooltip-left {
  margin-left: 0.5rem;
}
.tooltip-top {
  margin-top: 0.3rem;
}

/* LTBLoader--- starts here */

.splash {
  position: fixed;
  /* z-index: 2000; */
  background: white;
  color: gray;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
}
.splash-title {
  text-align: center;
  max-width: 500px;
  margin: 15% auto;
  padding: 20px;
}

/* .animated-loader {
  width: 10px;
  height: 10px;
  background: #f35626;
  color: #f35626;
  margin: 80px auto;
  box-shadow: 15px 15px 0 0, -15px -15px 0 0, 15px -15px 0 0, -15px 15px 0 0,
    0 15px 0 0, 15px 0 0 0, -15px 0 0 0, 0 -15px 0 0;
  animation: anim 2s linear infinite;
}

@keyframes anim {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }
  50% {
    box-shadow: 20px 20px 0 0, -20px -20px 0 0, 20px -20px 0 0, -20px 20px 0 0,
      0 10px 0 0, 10px 0 0 0, -10px 0 0 0, 0 -10px 0 0;
  }

  75% {
    box-shadow: 20px 20px 0 0, -20px -20px 0 0, 20px -20px 0 0, -20px 20px 0 0,
      0 10px 0 0, 10px 0 0 0, -10px 0 0 0, 0 -10px 0 0;
  }

  100% {
    transform: rotate(360deg);
    -webkit-filter: hue-rotate(360deg);
  }
} */

.spinner {
  margin: 20px auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 15px;
}
.spinner > div {
  background-color: #62cb31;
  height: 100%;
  width: 8px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* LTB loader ends here */

.modal-header {
  background: #f7f9fa;
}
.modal-footer {
  background: #f7f9fa;
}

/* Loader starts */

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: fixed;
  margin-left: 30rem;
  margin-top: 10rem;
  z-index: 99999;
}
#loader-1:before,
#loader-1:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #3498db;
}
#loader-1:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after {
  border: 10px solid #ccc;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

._hyperlink a {
  color: blue !important;
}

/* loader Ends here */

/* UnAuthorize page starts here */

/* .container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: #cbcbc4;
  z-index: -1;
}
.message {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  top: 150px;
  width: 500px;
  height: 400px;
  margin: 0 auto;
  border: 20px solid #d0cabf;
  background: #c4beb2;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 4px #471f05;
  animation-delay: 1s;
  animation-duration: 1.3s;
}
.message::before,
.message::after {
  content: "";
  position: absolute;
  bottom: 107%;
  width: 50px;
  height: 500px;
  background: url(https://i.postimg.cc/0QbqNZYv/chain-4.png) repeat-y center;
  z-index: -10;
}
.message::before {
  left: 20px;
}
.message::after {
  right: 20px;
}
.message-inner {
  padding: 40px;
  border-radius: 20px;
  position: absolute;
  top: 2%;
  right: 2%;
  bottom: 2%;
  left: 2%;
  color: #291b03;
  background: #d7d3cc;
}
.message-title {
  font-size: 4em;
  margin: 0 0 20px;
}
.message-subtitle {
  font-size: 2em;
  margin: 0;
}
.chain {
  position: absolute;
  top: 0;
  height: 200%;
  width: 50px;
  background: url(https://i.postimg.cc/0QbqNZYv/chain-4.png) repeat-y center;
  animation: chain 1.8s ease-in-out;
}
.chain.left {
  left: 0;
}
.chain.right {
  right: 0;
}
.cog {
  position: absolute;
  bottom: -50px;
}
.cog.left {
  left: -50px;
  animation: cog-spin-left 1.8s ease-in-out;
}
.cog.right {
  right: -50px;
  animation: cog-spin-right 1.8s ease-in-out;
}
.cog-outer {
  fill: #955112;
}
.cog-inner {
  fill: #633d03;
}
.rivet {
  position: absolute;
  background-color: #8f6002;
  width: 3%;
  border-radius: 100px;
  padding-bottom: 3%;
}
.rivet.top-left {
  top: -7px;
  left: -7px;
}
.rivet.top-right {
  top: -7px;
  right: -7px;
}
.rivet.bottom-left {
  bottom: -7px;
  left: -7px;
}
.rivet.bottom-right {
  bottom: -7px;
  right: -7px;
}
@keyframes cog-spin-left {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes cog-spin-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes chain {
  from {
    top: 0;
  }
  to {
    top: -100%;
  }
} */

/* UnAuthoize Page 2 */

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}

.notfound .notfound-404 {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0px auto 50px;
}

.notfound .notfound-404 > div:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #a8b2be7a;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 5px dashed #000;
  border-radius: 5px;
}

.notfound .notfound-404 > div:first-child:before {
  content: "";
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: -5px;
  top: -5px;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) inset;
  border-radius: 5px;
}

.notfound .notfound-404 h1 {
  font-family: "Cabin", sans-serif;
  color: #000;
  font-weight: 700;
  margin: 0;
  font-size: 90px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.notfound h4 {
  font-family: "Cabin", sans-serif;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 7px;
}

.notfound p {
  font-family: "Cabin", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.notfound a {
  font-family: "Cabin", sans-serif;
  display: inline-block;
  padding: 10px 25px;
  background-color: #8f8f8f;
  border: none;
  border-radius: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #2c2c2c;
}

.badgeMargin {
  margin-left: 0.15rem;
}
/* 
Button loader starts here  */

/* BTN loader ends here  */

/* select dropdown */
.form-control-xs {
  height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.2rem 0.25rem !important;
  font-size: 0.8rem !important;
  line-height: 1;
  border-radius: 0.2rem;
}
.btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}
.radio-btn-design {
  font-weight: 400;
  font-size: 14px;
}
.card-header-activity {
  display: flex;
  justify-content: space-between;
}
.display-tracker-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.copy-button-netdoc {
  border-radius: 0px 4px 4px 0px;
}
.copy-input-netdoc {
  border-radius: 4px 0px 0px 4px;
}
.calander-popup {
  z-index: 3;
}

.e-quick-popup-wrapper {
  height: auto;
  max-height: 424px !important;
  overflow-y: scroll;
}
.modal-header-font-size {
  font-size: 16px;
  font-weight: 500;
}
:root {
  --ck-z-default: 1;
  --ck-z-modal: calc(var(--ck-z-default) + 9999999999) !important;
}
.ck-editor__editable_inline {
  min-height: 300px !important;
}
.support-link {
  color: #3498db;
  font-weight: 500;
  font-size: "13px";
}
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-item {
  flex: 1;
}
.user-select-none {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.prompt-icons {
  font-size: 1.09375rem;
  text-align: center;
  transition: 0.3s;
  color: #5b5b5b;
  padding: 0;
  margin: 10px 0px 0px -12px;
}
.prompt-icons:nth-child(2) {
  margin-left: -14px;
}
.textBoldSub {
  font-size: 12px;
  font-weight: 600;
}

.tags .tag.primary {
  background-color: #999;
  border-color: #999;
  color: #fff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  min-height: 50px;
  background-color: #999;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}
::-webkit-scrollbar-thumb:active {
  background-color: #999;
}
