@use "./color-pallete" as pallet;
/*
  * $key is the style key i.e, "background" or "color"
  * $name is the theme key name which is being accessed
*/
@mixin theme-aware(
  $key,
  $name,
  $saturation: null,
  $lightness: null,
  $alpha: 1
) {
  @each $theme-name, $theme-color in pallet.$themes {
    .theme-#{$theme-name} & {
      $color: map-get(map-get(pallet.$themes, $theme-name), $name);

      @if (type-of($color) == "list") {
        $hue: nth($color, 1);
        $s: nth($color, 2);
        $l: nth($color, 3);
        @if ($saturation) {
          $s: $saturation;
        }
        @if ($lightness) {
          $l: $lightness;
        }

        $color: hsla($hue, $s, $l, $alpha);
      }
      #{$key}: $color;
    }
  }
}
