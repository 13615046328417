.form-wrapper {
  .form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    .form-group {
      flex-grow: 1;
    }
    margin: 0;
  }

  .form-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .form-check-input {
      margin: 0;
      position: relative;
    }
  }

  .form-group {
    label {
      margin-bottom: 0;
      font-size: 0.8rem;
      color: #607d8b;
    }
  }
}

.no-resize {
  resize: none;
}

.modal-dialog {
  .form-wrapper {
    max-height: 100%;
    // max-height: calc(100vh - 3.5rem);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .modal-body {
      // overflow-y: overlay;
    }
  }
}

.unset-overflow {
  .modal-body {
    overflow-y: unset;
  }
}

input[type="checkbox"] {
  height: .7rem;
  width: .7rem;
}
