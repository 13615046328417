@use "sass:color";
@use "../abstracts/mixins";
@use "../abstracts/colors";
@use "../theme";
@use "../abstracts/variables";

.avish-badge {
  padding: 0.1em 0.6em;
  border-radius: .15rem;
  @include theme.theme-aware("color", "primary-color", 1);
  @include theme.theme-aware(
    "background-color",
    "primary-color",
    $saturation: 52%,
    $lightness: 96%
  );
  font-size: 0.6rem;
  font-weight: 500;
  @include mixins.flex(inline-flex);
  gap: 0.2rem;
  width: fit-content;

  &:hover {
    @include theme.theme-aware(
      "background-color",
      "primary-color",
      $saturation: 100%,
      $lightness: 91%
    );
  }

  .close-icon {
    @include mixins.flex(inline-flex);
    color: #818181;
    cursor: pointer;
    height: 100%;
    padding: 0 0.2em;
    svg {
      @include mixins.size(1em);
    }
    &:hover {
      color: color.change(
        colors.$danger-color,
        $lightness: 41%,
        $saturation: 82%
      );
    }
  }
  &.primary {
    box-shadow: variables.$shadow-elevation-low;
    @include theme.theme-aware(
      "background-color",
      "primary-color",
      $saturation: 60%,
      $lightness: 93%
    );
    @include theme.theme-aware(
      "color",
      "primary-color",
      $saturation: 52%,
      $lightness: 40%
    );
  }
  &.danger {
    background-color: color.adjust(
      colors.$danger-color,
      $lightness: 45%,
      $saturation: 100%
    );
    color: colors.$danger-color;
  }
  &.success {
    background-color: color.adjust(
      colors.$success-color,
      $lightness: 45%,
      $saturation: 100%
    );
    color: colors.$success-color;
  }
  &.warning {
    background-color: color.adjust(
      colors.$warning-color,
      $lightness: 45%,
      $saturation: 100%
    );
    color: colors.$warning-color;
  }
  &.secondary {
    background-color: color.adjust(
      colors.$secondary-color,
      $lightness: 45%,
      $saturation: 100%
    );
    color: colors.$secondary-color;
  }
  &.info {
    @include theme.theme-aware(
      "background-color",
      "primary-color",
      $saturation: 100%,
      $lightness: 93%
    );
    @include theme.theme-aware("color", "primary-color");
  }
  &.default {
    background-color: color.adjust(#000, $lightness: 45%, $saturation: 0%);
    color: #000;
  }
}
