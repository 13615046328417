@use "../theme";

.btn-primary {
  color: #fff;
  @include theme.theme-aware("background-color", "primary-color");
  @include theme.theme-aware("background-color", "primary-color");
}

.c-body {
  @include theme.theme-aware("background-color", "color-background");
  @include theme.theme-aware("color", "color-text-1");
}
